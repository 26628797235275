import { httpDelete, httpGet, httpPost, httpPut } from "@/api/request";
import $store from "@/store";

// 发货
export function ADD_GOODS_SEND(params) {
    return httpPost("/goods/shipment", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取发货列表
export function GET_GOODS_SEND_LIST(params) {
    return httpGet("/goods/shipment", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 批量删除发货
export function BATCH_DELETE_GOODS_SEND(params) {
    return httpDelete("/goods/shipment", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取发货电杆详情
export function GET_GOODS_SEND_POLE_DETAIL(params) {
    return httpGet(`/goods/shipment/pole/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取资产用户列表-添加发货用
export function GET_GOODS_SEND_USERS(params) {
    return httpGet("/goods/shipment/assets/user", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取发货电杆列表 - 添加发货用
export function GET_GOODS_SEND_POLES(params) {
    return httpGet("/goods/shipment/pole", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取收货列表
export function GET_GOODS_RECEIVE_LIST(params) {
    return httpGet(`/goods/order/${params.type}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取收货电杆详情
export function GET_GOODS_RECEIVE_POLE_DETAIL(params) {
    return httpGet(`/goods/shipment/pole/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 更改收货状态-确认收货用
export function UPDATE_GOODS_RECEIVE_STATUS(params) {
    return httpPut(`/goods/order/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取安装列表
export function GET_GOODS_INSTALL_LIST(params) {
    return httpGet(`/goods/install/${params.status}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取安装详情
export function GET_GOODS_INSTALL_DETAIL(params) {
    return httpGet(`/goods/install/detail/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取安装-施工单位列表
export function GET_GOODS_INSTALLER_LIST(params) {
    return httpGet("/goods/install/build/user", params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 新增安装订单添加施工方单位
export function ADD_GOODS_INSTALL(params) {
    return httpPut(`/goods/install/build/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取售后列表
export function GET_GOODS_AFTER_SALE_LIST(params) {
    return httpGet(`/goods/after/${params.status}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取售后详情
export function GET_GOODS_AFTER_SALE_DETAIL(params) {
    return httpGet(`/goods/after/detail/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取审批列表
export function GET_GOODS_APPROVAL_LIST(params) {
    return httpGet(`/goods/after/check/${params.status}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 获取审批详情
export function GET_GOODS_APPROVAL_DETAIL(params) {
    return httpGet(`/goods/after/check/detail/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}

// 更新审批状态
export function UPDATE_GOODS_APPROVAL_STATUS(params) {
    return httpPut(`/goods/after/check/${params.id}`, params, {
        headers: {
            Authorization: `Bearer ${$store.getters.getToken}`
        }
    });
}
