<template>
    <el-dialog
        :model-value="show"
        :before-close="onClose"
        :close-on-click-modal="false"
        :title="id ? '编辑用户' : '新增用户'"
        custom-class="common-edit"
        width="600px"
    >
        <el-form ref="dataFormRef" :label-width="100" :model="dataForm" :rules="dataRules" label-position="left">
            <el-form-item label="用户名：" prop="name">
                <el-input v-model="dataForm.name" placeholder="请输入用户名"></el-input>
            </el-form-item>
            <el-form-item label="所属角色：" prop="roles">
                <el-select v-model="dataForm.roles" filterable multiple placeholder="请选择所属角色">
                    <el-option v-for="item in roleOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
                <el-input v-model="dataForm.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="password">
                <el-input v-model="dataForm.password" placeholder="请输入密码" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="password_confirmation">
                <el-input
                    v-model="dataForm.password_confirmation"
                    placeholder="请再次输入密码"
                    type="password"
                ></el-input>
            </el-form-item>
            <el-form-item label="所属单位：">
                <el-select v-model="dataForm.unit_id" filterable placeholder="请选择所属角色">
                    <el-option v-for="item in unitOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button @click="onClose">取消</el-button>
            <el-button type="primary" @click="onSubmit">提交</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, watch, nextTick } from "vue";
import { ADD_USER, EDIT_USER, GET_ALL_ROLE_LIST } from "@/api/permission";
import { GET_GOODS_INSTALLER_LIST } from "@/api/goods";
import { GET_PATROL_UNIT_LIST } from "@/api/patrol";
import { ElMessage } from "element-plus";

let props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    id: { type: [String, Number] },
    detail: { type: Object }
});

let emit = defineEmits(["update:show", "update"]);

watch(
    () => ({ id: props.id, show: props.show }),
    (newVal) => {
        if (newVal.show) getRoleList();
        if (newVal.show && newVal.id) getDetail();
    },
    { deep: true }
);

let roleOptions = ref([]);
let unitOptions = ref([]);

let getRoleList = () => {
    GET_ALL_ROLE_LIST({}).then((res) => {
        roleOptions.value = res.data.data;
        console.log(res.data.data);
    });
    GET_GOODS_INSTALLER_LIST({}).then((res) => {
        unitOptions.value = res.data.data;
        GET_PATROL_UNIT_LIST({}).then((cres) => {
            unitOptions.value = unitOptions.value.concat(cres.data.data);
        });
    });
};

// 获取详情
let getDetail = async () => {
    await nextTick();
    let roles = props.detail.roles_node.map((item) => {
        return item.id;
    });
    dataForm.value = {
        name: props.detail.name,
        roles: roles,
        email: props.detail.email,
        password: null,
        password_confirmation: null,
        unit_id: props.detail.unit_id
    };
};

// 表单数据
let dataForm = ref({
    name: null,
    roles: [],
    email: null,
    password: null,
    password_confirmation: null,
    unit_id: null
});

let dataFormRef = ref();

let dataRules = {
    name: [{ required: true, message: "请输入用户名！", trigger: "blur" }],
    roles: [{ required: true, message: "请选择所属角色！", trigger: "change" }],
    email: [{ required: true, message: "请输入邮箱！", trigger: "blur" }],
    password: [{ required: true, message: "请输入密码！", trigger: "blur" }],
    password_confirmation: [{ required: true, message: "请再次输入密码！", trigger: "blur" }]
};

// 关闭
let onClose = () => {
    if (dataFormRef.value) dataFormRef.value.resetFields();
    emit("update");
    emit("update:show", false);
};

// 提交
let onSubmit = () => {
    dataFormRef.value.validate((valid) => {
        if (valid) {
            if (!props.id) {
                ADD_USER({ ...dataForm.value }).then((res) => {
                    if (res.data.code === 200) {
                        onClose();
                        ElMessage.success("新增成功");
                    }
                });
            } else {
                EDIT_USER({ ...dataForm.value, id: props.id }).then((res) => {
                    if (res.data.code === 200) {
                        onClose();
                        ElMessage.success("编辑成功");
                    }
                });
            }
        }
    });
};
</script>
